import { Item } from '@/interfaces';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

interface Body {
  first_name?: string;
  last_name: string;
  previous_email: string;
  email?: string;
  id_verified?: string;
}

export const editSubAdmin = async (body: Body) => {
  try {
    const resp = await httpClient().post(
      `/admin/admineditusr`,
      body,
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('data updated');
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to update data');
    console.error(error);
  }
};
