import(/* webpackMode: "eager" */ "/app/views/Admins/Admins.tsx");
import(/* webpackMode: "eager" */ "/app/views/Auth/Auth.tsx");
import(/* webpackMode: "eager" */ "/app/views/Categories/Categories.tsx");
import(/* webpackMode: "eager" */ "/app/views/Home/Home.tsx");
import(/* webpackMode: "eager" */ "/app/views/Items/Items.tsx");
import(/* webpackMode: "eager" */ "/app/views/Profile/Profile.tsx");
import(/* webpackMode: "eager" */ "/app/views/RentalPoint/RentailPoint.tsx");
import(/* webpackMode: "eager" */ "/app/views/RentalPoints/RentalPoints.tsx");
import(/* webpackMode: "eager" */ "/app/views/SubCompanies/Legal/LegalList.tsx");
import(/* webpackMode: "eager" */ "/app/views/SubCompanies/Private/PrivateList.tsx");
import(/* webpackMode: "eager" */ "/app/views/Subscriptions/Subscriptions.tsx");
import(/* webpackMode: "eager" */ "/app/views/Users/Users.tsx")