import { useUser } from '@/hooks/useUser';
import { User } from '@/interfaces/user';
import { errorNotification, getUserAttribute } from '@/util';
import { httpClient } from '@/util/httpClient';

export const getUser = async (token?: any) => {
  try {
    const resp = await httpClient().post('/admin/getusrcreds', {
      access_token: token,
    });

    if (resp.status === 200) {
      const data = resp.data.UserAttributes;
      const image = await httpClient().post('/subadmin/image/get/aws', {
        aws_id: getUserAttribute(data, 'sub'),
      });

      const user: User = {
        awsId: getUserAttribute(data, 'sub'),
        guid: resp.data.Username,
        email: getUserAttribute(data, 'email'),
        name: getUserAttribute(data, 'given_name'),
        surname: getUserAttribute(data, 'family_name'),
        phone: getUserAttribute(data, 'phone_number'),
        status: getUserAttribute(data, 'custom:status'),
        emailVerified: getUserAttribute(data, 'email_verified') === 'True',
        companyId: getUserAttribute(data, 'custom:company_id'),
        role: getUserAttribute(data, 'custom:role'),
        imageUrl: image.status === 200 && image.data !== 404 ? image.data : '',
        id_verified: getUserAttribute(data, 'custom:id_verified'),
      };
      
      useUser.getState().setUser(user);

      return user
    }
  } catch (error) {
    errorNotification('Failed to get user data');
    console.error(error);
  }
};
